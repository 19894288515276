// import * as React from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import { DarkModeContext } from './DarkModeContext';

// export default function AppModal({
//     open,
//     setOpen
// }) {

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const { darkMode } = React.useContext(DarkModeContext);

//     return (
//         <div style={{ background: darkMode ? 'black' : 'white' }}>
//             <Dialog
//                 open={open}
//                 onClose={handleClose}
//             >
//                 <DialogContent>
//                     <h2 id="modal-modal-title" style={{ fontSize: 23, fontWeight: 500, color: darkMode ? 'white' : 'black' }}>
//                         Caution : Software Development Agency
//                     </h2>
//                     <p id="modal-modal-description" style={{ marginTop: '2%', opacity: '70%', fontSize: 15, color: darkMode ? 'white' : 'black' }}>
//                         We take pride in delivering top-notch solutions to a diverse range of clients.
//                         Here are some of the brands we've had the pleasure of working with.We take pride in delivering top-notch solutions to a diverse range of clients. Here are some of the brands we've had the pleasure of working with.
//                     </p>
//                 </DialogContent>
//             </Dialog>
//         </div>
//     );
// }


import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DarkModeContext } from './DarkModeContext';

export default function AppModal({
    open,
    setOpen
}) {

    const handleClose = () => {
        setOpen(false);
    };

    const { darkMode } = React.useContext(DarkModeContext);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        backgroundColor: darkMode ? 'black' : 'white',
                        color: darkMode ? 'white' : 'black',
                    }
                }}
            >
                <DialogContent>
                    <h2 id="modal-modal-title" style={{ fontSize: 23, fontWeight: 500 }}>
                        Welcome to CodeStep Technologies
                    </h2>
                    <p id="modal-modal-description" style={{ marginTop: '2%', opacity: '70%', fontSize: 15 }}>
                        Innovative Software Solutions and Branding Excellence
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{ color: darkMode ? 'white' : 'black' }}>Explore now</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

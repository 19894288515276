import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import Heading from './Heading';
import { DarkModeContext } from './DarkModeContext';
import { FaLinkedin } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { primaryDarkColor } from '../../constant';

const Team = () => {

    const navigate = useNavigate()
    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 7%',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            textAlign: 'center',
            color: darkMode ? '#fff' : '#000',
        },
        header: {
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '20px',
        },
        teamGrid: {
            display: 'grid',
            gap: '20px',
            gridTemplateColumns: isSmallScreen
                ? 'repeat(1, 1fr)'
                : isMediumScreen
                    ? 'repeat(2, 1fr)'
                    : 'repeat(4, 1fr)',
            alignItems: 'center',
        },
        teamMember: {
            backgroundColor: darkMode ? '#333' : '#F5F5F5',
            padding: '20px',
            borderRadius: '10px',
            textAlign: 'center',
        },
        memberImage: {
            width: '70px',
            height: '70px',
            borderRadius: '50%',
            marginBottom: '10px',
            border: '2px solid black'
        },
        memberName: {
            fontSize: '18px',
            fontWeight: 600,
            marginBottom: '5px',
        },
        memberSocial: {
            fontSize: '14px',
            color: darkMode ? '#bbb' : '#aaa',
            cursor: 'pointer'
        },
        memberRole: {
            opacity: '70%',
            margin: 0,
            padding: 0,
            fontSize: 13
        }
    };

    const teamMembers = [
        { image: '/images/chetan.png', name: 'Chethan K R', role: '(Founder and Managing Director)', social: <FaLinkedin style={{ height: 25, width: 25, color: '#0A63BC' }} />, link: 'https://www.linkedin.com/in/chethan-k-r-reddy/' },
        { image: '/images/bala.jpg', name: 'Bala Krishna V', role: '(Co-founder and CEO)', social: <FaLinkedin style={{ height: 25, width: 25, color: '#0A63BC' }} />, link: 'https://www.linkedin.com/in/bala-krishna-v/' },
        { image: '/images/tarun.jpg', name: 'Tarun K R Reddy', role: '(Co-founder and CFO)', social: <FaLinkedin style={{ height: 25, width: 25, color: '#0A63BC' }} />, link: 'https://www.linkedin.com/in/drtarunkrr/' },
        { image: '/images/prathik.jpeg', name: 'Prathik Korwar', role: '(Co-founder and COO)', social: <FaLinkedin style={{ height: 25, width: 25, color: '#0A63BC' }} />, link: 'https://www.linkedin.com/in/prathik-korwar-35013b216/' },
    ];

    const handleSocialClick = (item) => {
        navigate(item?.link)
    }

    return (
        <div style={styles.container}>
            <Heading title={'Our Team'} />
            <div style={styles.teamGrid}>
                {teamMembers.map((member, index) => (
                    <div key={index} style={styles.teamMember}>
                        <img src={member.image} alt={member.name} style={styles.memberImage} />
                        <div style={styles.memberName}>{member.name}</div>
                        <p style={styles.memberRole}>{member.role}</p>
                        <a href={member.link} target='_blank' style={styles.memberSocial}>{member.social}</a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Team;